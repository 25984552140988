import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
// import AuthenticatedRoutes from "../routes/RouteCheck";
// // routes config
// import routes from "../routes/routes";

const Chat = lazy(() => import("../../components/Chat/Chat"))
const Join = lazy(() => import("../../components/Join/Join"))

// const getRoutes = (allRoutes) => {
//     return allRoutes.map((route, idx) => {
//         console.log("route::", route)
//         if (route.element) {
//             return (
//                 <React.Fragment key={idx}>
//                     {!route.authenticated && (
//                         <>
//                             <Route
//                                 path={route.path}
//                                 exact={route.exact}
//                                 name={route.name}
//                                 element={<route.element />}
//                             />
//                         </>
//                     )}
//                     {
//                         <React.Fragment>
//                             <Route path="/" element={<AuthenticatedRoutes />}>
//                                 <Route
//                                     path={route.path}
//                                     exact={route.exact}
//                                     name={route.name}
//                                     element={<route.element />}
//                                 />
//                             </Route>
//                         </React.Fragment>
//                     }
//                 </React.Fragment>
//             );
//         }
//         return null;
//     });
// };

const AppRoute = () => {
    return (
        <Suspense>
            {/* <Routes>{getRoutes(routes)}</Routes> */}
            <Routes>
                
                {['/', '/join'].map((path, i) => (
                    <Route
                        key={i}
                        path={path}
                        exact
                        name='join'
                        element={<Join />}
                    />
                ))}
                <Route
                    path='/chat'
                    name='chat'
                    element={<Chat />}
                />
            </Routes>
        </Suspense>
    );
};

export default AppRoute;
